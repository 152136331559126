import { colors, LabeledCheckbox } from "@commonsku/styles";
import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const LABEL_STYLES: React.CSSProperties = {
    marginBottom: "6px",
    marginRight: 0,
    fontSize: "14px",
    fontWeight: 400,
    color: colors.primary1[65],
};

const Checkbox = ({
    values,
    onChange,
    placeholder,
}) => {
    const isChecked = values as boolean;

    return (
        <CheckboxContainer>
            <LabeledCheckbox
                label={placeholder}
                checked={isChecked}
                onChange={(e) => onChange(e.target.checked)}
                labelStyle={LABEL_STYLES}
            />
        </CheckboxContainer>
    );
}

export default Checkbox;
